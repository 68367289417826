import React from 'react';
import { BeatLoader} from 'react-spinners';
// https://www.davidhu.io/react-spinners/

import {css} from '@emotion/core';

const classLoaderNormal = css`
    margin-top: 10px;
    margin-bottom: 10px;
`
const classLoaderCenter = css`
    width: 100%;
    justify-content: center;
    align-items: center;
`
const classLoaderFixedCenter = css`
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
    margin-top: -..px; /* half of the elements height */
    margin-right: -..px; /* half of the elements width */
`
const LoadingPage = props  =>  {
 
    const css = props.css === undefined ? classLoaderNormal : props.css === "classLoaderCenter" ? classLoaderCenter : props.css === "classLoaderFixedCenter" ? classLoaderFixedCenter : classLoaderNormal;
    const color = props.color === undefined ? "#2A3679" : props.color;
    const size  = props.size === undefined ? 20 : props.size;
   
    return(
        <div>                            
            <BeatLoader 
                css={css}
                size={size}
                color={color} 
                loading   
            />                            
        </div>
    );
}

export default LoadingPage;